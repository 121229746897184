<template>
    <section class="pt-3">
        <div class="row mx-2 px-3">
            <div class="rounded-circle d-middle-center cr-poiter bg-general3 text-white" style="width:20px;height:20px;" @click="$router.back()">
                <i class="icon-left" />
            </div>
            <div class="col-auto text-general f-600">
                Ruta {{ ruta.id }}
            </div>
            <div class="col-auto text-general f-15">
                Peso: {{ agregarSeparadoresNumero(totales.total_peso,2) }} Kg
            </div>
            <div class="col-auto text-general f-15">
                Volumen: {{ agregarSeparadoresNumero(totales.total_volumen) }} m3
            </div>
        </div>
        <div class="row mx-3 align-items-center text-general px-3 my-2 py-2 br-12 bg-white">
            <div class="bg-general2 br-8 d-middle-center shadow text-white px-2 cr-pointer" style="height:32px;" @click="openModalBuscarPedido">
                Buscar Pedidos
            </div>
            <div class="col-auto ml-5">
                Criterios:
            </div>
            <i class="icon-fecha-fin f-20 text-general ml-2" />
            <div v-if="criterios.fechas.length" class="col-auto pl-2">
                <p>{{ criterios.fechas[0] }} </p>
                <p>{{ criterios.fechas[1] }}</p>
            </div>
            <div v-else class="col-auto pl-2">
                <p>No Aplica </p>
            </div>
            <i class="icon-clock f-20 text-general ml-2" />
            <div v-if="criterios.horario.length" class="col-auto pl-2">
                <p>{{ criterios.horario[0] }}:00 Hrs. </p>
                <p>{{ criterios.horario[1] }}:00 Hrs.</p>
            </div>
            <div v-else class="col-auto pl-2">
                <p>No Aplica </p>
            </div>
            <i class="icon-ruler f-20 text-general ml-2" />
            <div class="col-auto pl-2">
                <p>{{ criterios.distancia_minima ? criterios.distancia_minima + 'Mts' : 'No Aplica' }}  </p>
                <p>{{ criterios.distancia_maxima ? criterios.distancia_maxima + 'Mts' : 'No Aplica' }}  </p>
            </div>
            <i class="icon-pedidos ml-2 f-20 text-general" />
            <div v-if="criterios.estados.length" class="col-auto d-flex pl-2">
                {{ criterios.estados[0] }}
                <div class="bg-general3 text-white rounded-circle d-middle-center ml-3" style="width:25px;height:25px;">
                    {{ criterios.estados.length }}
                </div>
            </div>
            <div v-else class="col-auto d-flex pl-2">
                No Aplica
            </div>
            <i class="icon-cedis f-20 text-general ml-3" />
            <div v-if="criterios.cedis.length" class="col-auto d-flex pl-2">
                {{ criterios.cedis[0] }}
                <div class="bg-general3 text-white rounded-circle d-middle-center ml-3" style="width:25px;height:25px;">
                    {{ criterios.cedis.length }}
                </div>
            </div>
            <div v-else class="col-auto d-flex pl-2">
                No Aplica
            </div>
            <i class="icon-leechero-cap f-20 text-general ml-2" />
            <div class="col-auto d-flex pl-2">
                {{ criterios.lechero }}
            </div>
        </div>
        <div class="row mx-3 mt-2">
            <!-- Resultados -->
            <div class="col px-0 bg-white mx-2 br-t-12">
                <div class="row mx-0 border-bottom align-items-center" style="height:45px;">
                    <div class="col-auto pl-2 text-general f-17 f-600">
                        Resultados
                    </div>
                    <div class="br-5 ml-auto text-white bg-general3 d-middle-center px-2">
                        {{ pedidosBusqueda.length }}
                    </div>
                    <el-tooltip v-show="pedidosBusqueda.length" placement="bottom" content="Añadir todos los pedidos" effect="light">
                        <i class="icon-plus-box-multiple text-general-red f-25" />
                    </el-tooltip>
                </div>
                <div class="custom-scroll overflow-auto pt-2" style="height:calc(100vh - 249px)">
                    <div v-for="(p,index) in pedidosBusqueda" :key="index" class="row mx-0 my-2 mr-2">
                        <div class="col px-0 mx-2 border py-1 px-2 br-8">
                            <div class="row mx-0">
                                <i class="icon-pedidos text-general f-18" />
                                <div class="col pl-2 pr-0 text-general f-15">
                                    <p class="f-500">
                                        No. {{ p.id_admin_pedido }}
                                    </p>
                                    <p>
                                        {{ p.fecha_entrega | helper-fecha('DD MMM Y hh:mm a') }}
                                    </p>
                                </div>
                                <div v-if="p.en_otra_ruta" class="col-auto px-0">
                                    <el-tooltip v-show="p.partido === null" placement="bottom" content="Pedido Parcial" effect="light">
                                        <i class="icon-timelapse text-gris f-17" />
                                    </el-tooltip>
                                    <el-tooltip v-show="p.partido === 0" placement="bottom" content="Este pedido esta ya esta en otra ruta" effect="light">
                                        <i class="icon-alert-triangle text-warning f-17" />
                                    </el-tooltip>
                                </div>
                            </div>
                            <div class="row mx-0 align-items-center f-15 text-general2">
                                <i class="icon-leechero-cap f-18" />
                                <p class="col pl-2 f-500">
                                    {{ p.usuario }}
                                </p>
                            </div>
                        </div>
                        <div class="col-auto px-0 d-middle">
                            <div v-if="p.en_otra_ruta" class="br-4 bg-general d-middle-center" style="width:20px">
                                <i v-show="p.partido === null" class="icon-plus text-white f-14 cr-pointer" @click="agregarPedido(p.id_admin_pedido)" />
                            </div>
                            <div v-else class="br-4 bg-general d-middle-center" style="width:20px">
                                <i class="icon-plus text-white f-14 cr-pointer" @click="agregarPedido(p.id_admin_pedido)" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Añadidos -->
            <div class="col px-0 bg-white mx-3 br-t-12">
                <div class="row mx-0 border-bottom align-items-center" style="height:45px;">
                    <div class="col-auto pl-2 text-general f-17 f-500">
                        Añadidos
                    </div>
                    <div class="col">
                        <p class="text-general f-15">
                            Peso: {{ agregarSeparadoresNumero(totales.total_peso,2) }} Kg
                        </p>
                        <p class="text-general f-15">
                            Volumen: {{ agregarSeparadoresNumero(totales.total_volumen) }} m3
                        </p>
                    </div>
                    <div class="text-white bg-general3 d-middle-center mr-1 px-2 br-5">
                        {{ pedidos.length }}
                    </div>
                    <i class="icon-close-circle-multiple text-general-red f-25 cr-pointer" />
                </div>
                <div class="custom-scroll overflow-auto pt-2" style="height:calc(100vh - 249px)">
                    <div v-for="(p,key) in pedidos" :key="key" class="row mx-2 my-2">
                        <div v-show="p.id_admin_pedido == pedidoActivo.id_admin_pedido" class="col-auto px-1 bg-general br-20" />
                        <div class="col px-0 mx-2 border py-1 px-2 br-8 cr-pointer" @click="detallePedido(p)">
                            <div class="row mx-0">
                                <i class="icon-pedidos text-general f-18" />
                                <div class="col pl-2 pr-0 text-general f-15">
                                    <p class="f-500">
                                        No. {{ p.id_admin_pedido }}
                                    </p>
                                    <p>
                                        {{ p.fecha_entrega | helper-fecha('DD MMM Y hh:mm a') }}
                                    </p>
                                </div>
                            </div>
                            <div class="row mx-0 align-items-center f-15 text-general2">
                                <i class="icon-leechero-cap f-18" />
                                <p class="col pl-2">
                                    {{ p.usuario }}
                                </p>
                            </div>
                        </div>
                        <div class="col-auto px-1 d-middle">
                            <div class="rounded-circle bg-gris">
                                <el-tooltip placement="bottom" content="Eliminar" effect="light">
                                    <i class="icon-cancel text-general cr-pointer" @click="eliminarPedidoRuta(p)" />
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Detalle productos -->
            <div class="col-5 px-0 bg-white mx-2 br-t-12">
                <div class="row mx-0 border-bottom align-items-center" style="height:45px;">
                    <i class="icon-shopping-outline text-general f-20" />
                    <div class="col-auto pl-0 text-general f-17 f-600">
                        No. {{ pedidoActivo.id_admin_pedido }}
                    </div>
                    <template v-if="pedidoActivo.cant_productos > 0 && pedidoActivo.partido === null">
                        <div class="col-auto text-general d-middle">
                            <b>Productos</b>
                            <div class="text-white ml-1 bg-general3 d-middle-center br-5 px-2 ml-2">
                                {{ pedidoActivo.cant_productos }}
                            </div>
                        </div>
                        <div class="col-auto px-0 ml-auto mr-2">
                            <div class="br-20 bg-general3 text-white px-3 cr-pointer f-15" @click="openModalEntrega()">
                                Completar Cantidades
                            </div>
                        </div>
                    </template>
                </div>
                <div class="custom-scroll overflow-auto" style="height:calc(100vh - 250px)">
                    <div class="row mx-0 my-4 justify-center">
                        <div v-if="pedidoActivo.partido === 0" class="col-11 bg-smoke border br-4 d-middle py-3 cr-pointer">
                            <div class="col-auto text-general f-17 f-600">
                                Productos
                            </div>
                            <div class="d-middle-center bg-general3 text-white ml-2 br-5 px-2">
                                {{ pedidoActivo.cant_productos }}
                            </div>
                            <div class="f-14 bg-general3 d-middle-center ml-auto text-white br-20 px-3 py-1" @click="accionPartirPedido">
                                Partir el pedido
                            </div>
                        </div>
                        <template v-else>
                            <div class="col-12 px-0">
                                <div v-for="(prod, index2) in productos" :key="index2" class="row mx-0 mb-3">
                                    <img :src="prod.imagen" width="70" height="80" class="obj-cover" />
                                    <div class="col pl-2">
                                        <p class=" mb-2 text-general f-14">{{ prod.nombre }}</p>
                                        <p class="text-general2 f-14 mb-2">
                                            {{ prod.presentacion }}
                                        </p>
                                        <div class="row mx-0 text-general">
                                            <div class="col px-0 f-12 text-center">
                                                <b class="f-15">
                                                    {{ agregarSeparadoresNumero(prod.cantidad,decimalFraccion(prod)) }}
                                                </b>

                                                <p>Cant. pedida</p>
                                            </div>
                                            <div class="col px-0 f-12 text-center">
                                                <b class="f-15">
                                                    {{ agregarSeparadoresNumero(prod.cant_otras_rutas,decimalFraccion(prod)) }}
                                                </b>

                                                <p>En otras rutas</p>
                                            </div>
                                            <div class="col px-0 f-12 text-center">
                                                <div class="d-middle">
                                                    <el-input v-model="prod.cant_en_ruta" size="small" class="w-100" />
                                                    <i v-show="prod.cant_en_ruta" class="icon-plus bg-general text-white br-5 ml-1 cr-pointer" @click="setEntrega(prod)" />
                                                </div>
                                                <p>En esta ruta</p>
                                            </div>
                                            <div class="col px-0 f-12 text-center">
                                                <b class="f-15">
                                                    {{ agregarSeparadoresNumero(prod.cant_por_entregar,decimalFraccion(prod)) }}
                                                </b>

                                                <p>Por entregar</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <!-- Partials -->
            <modal-buscar-pedidos ref="buscarPedidos" @buscar="buscarPedidos" />
            <modal-entrega-completa ref="modalEntregaCompleta" @update="pedidoActivo.partido = 0" />
        </div>
    </section>
</template>

<script>
import Routing from '~/services/routing/rutas'
export default {
    components: {
        modalBuscarPedidos: () => import('./partials/modalBuscarPedidos'),
        modalEntregaCompleta: () => import('./partials/modalEntregaCompleta')
    },
    data(){
        return {
            completar: false,
            url_producto: 'https://www.las2orillas.co/wp-content/uploads/2021/04/biscolata-600x600.jpeg',
            cantidad_entrega: '',
            ruta:{},
            pedidos:[],
            totales:{total_peso: 0, total_volumen:0},
            pedidosBusqueda:[],
            criterios:{cedis:[],estados:[],fechas:[],horario:[]},
            productos:[],
            pedidoActivo:{}
        }
    },
    computed:{
        id_ruta(){
            return Number(this.$route.params.id_ruta)
        }
    },
    mounted(){
        this.listarPedidos()
    },
    methods: {
        async listarPedidos(){
            try {

                const {data} = await Routing.pedidosXRuta(this.id_ruta)
                this.ruta = data.ruta
                this.pedidos = data.pedidos
                if(data.totales){
                    this.totales = data.totales
                }

            } catch (e){
                this.error_catch(e)
            }
        },
        openModalEntrega(){
            this.$refs.modalEntregaCompleta.toggle(this.pedidoActivo.id_admin_pedido);
        },
        openModalBuscarPedido(){
            this.$refs.buscarPedidos.toggle();
        },
        async buscarPedidos(params){
            try {
                //console.log(params);
                this.criterios = params.datos
                const {data} = await Routing.buscarPedidos(this.id_ruta, params.params)
                this.pedidosBusqueda = data.pedidos
                if(this.pedidosBusqueda.length){
                    this.$refs.buscarPedidos.toggle();
                }else{
                    this.notificacion('Sin Datos','No hemos encontrado coincidencias con tu busqueda, por favor intenta otra combinación','warning')
                }

            } catch (e){
                this.error_catch(e)
            }
        },
        async agregarPedido(id_pedido){
            try {

                const {data} = await Routing.agregarPedido(this.id_ruta, id_pedido)
                this.notificacion('Ruta actualizada','se ha añadido un pedido','success')
                const index = this.pedidosBusqueda.findIndex(o=>o.id_admin_pedido == id_pedido)
                this.pedidosBusqueda.splice(index,1)
                this.listarPedidos()


            } catch (e){
                this.error_catch(e)
            }
        },
        async detallePedido(pedido){
            try {
                this.pedidoActivo = pedido
                if(pedido.partido === 0) return
                const {data} = await Routing.detallePedido(this.id_ruta, pedido.id_admin_pedido)
                this.productos = data.productos

            } catch (e){
                this.error_catch(e)
            }
        },
        decimalFraccion(p){
            if(p.cantidad_tipo === 1) return 0
            else return 2
        },
        async setEntrega(prod){
            try {

                console.log(prod);
                if(isNaN(prod.cant_en_ruta) || prod.cant_en_ruta == 0){
                    this.notificacion('Advertencia',`Por favor ingrese un numero valido`,'warning')
                    return
                }

                const maximo = prod.cantidad - prod.cant_otras_rutas

                if(prod.cant_en_ruta > maximo){
                    this.notificacion('Advertencia',`La cantidad maxima a entregar es ${maximo}`,'warning')
                    return
                }

                const form = {
                    id_ruta:this.id_ruta,
                    id_pedido: this.pedidoActivo.id_admin_pedido,
                    id_admin_pedido_producto: prod.id,
                    cantidad: parseFloat(prod.cant_en_ruta)
                }
                const {data} = await Routing.setEntrega(form)
                this.notificacion('','Cantidad actualizada','success')
                this.detallePedido(this.pedidoActivo)

            } catch (e){
                this.error_catch(e)
            }
        },
        async accionPartirPedido(){
            try {
                let id_ruta_pedido = this.pedidoActivo.id
                if(!id_ruta_pedido) return
                const {data} = await Routing.partirPedido(id_ruta_pedido)
                this.pedidoActivo.partido = null
                this.detallePedido(this.pedidoActivo)

            } catch (e){
                this.error_catch(e)
            }
        },
        async eliminarPedidoRuta(p){
            try {

                const {data} = await Routing.eliminarPedidoRuta(this.id_ruta, p.id_admin_pedido)
                let index =  this.pedidos.findIndex(o=>o.id_admin_pedido == p.id_admin_pedido)
                this.pedidos.splice(index,1)
                this.notificacion('Ruta actualizada',`Se ha eliminado el pedido de la ruta`,'success')

            } catch (e){
                this.error_catch(e)
            }
        }

    }
}
</script>
<style lang="scss" scoped>
.bg-smoke{
    background-color: #F6F9FB;
}
</style>
