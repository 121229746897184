import axios from 'axios'

const API = 'routing'

const Routing = {
    pedidosXRuta(idRuta){
        return axios(`${API}/ruta/${idRuta}/pedidos`);
    },
    getLecheros(params){
        return axios(`${API}/select/lecheros`,{params});
    },
    buscarPedidos(id_ruta, params){
        return axios(`${API}/ruta/${id_ruta}/buscador/pedidos`,{params});
    },
    agregarPedido(id_ruta, id_pedido){
        return axios.post(`${API}/ruta/${id_ruta}/add/pedido/${id_pedido}`);
    },
    detallePedido(id_ruta, id_pedido){
        return axios.get(`${API}/ruta/${id_ruta}/pedido/${id_pedido}`);
    },
    setEntrega(form){
        return axios.put(`${API}/ruta/${form.id_ruta}/pedido/producto/set-cantidad`, form);
    },
    partirPedido(id_ruta_pedido){
        return axios.put(`${API}/ruta/pedido/${id_ruta_pedido}/dividir`);
    },
    getOtrasRutas(params){
        return axios.get(`${API}/ruta/pedido`,{params});
    },
    setEntregaCompleta(form){
        return axios.put(`${API}/ruta/pedido/set-entrega`,form);
    },
    eliminarPedidoRuta(id_ruta, id_pedido){
        return axios.delete(`${API}/ruta/${id_ruta}/pedido/${id_pedido}`);
    },
}

export default Routing
